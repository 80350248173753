import React from 'react';
import pink from '../assets/pink.jpg';
import black from '../assets/black.jpg';
import blue from '../assets/blue.jpg';
import { FaShoppingBag, FaBell, FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { RxAvatar } from "react-icons/rx";
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const ComingSoon = () => {
  return (
    <div className=" flex flex-col">
      <header className="bg-white py-4  bg-gray-100">
        <div className="container mx-auto flex justify-between items-start  px-4 md:px-0">
          
          <div className="relative w-full md:w-auto">
            <input
              type="search"
              placeholder="Search..."
              className="pl-8 border-b border-black px-3 py-1 mx-2 md:w-full  md:w-searchW rounded-none focus:outline-none focus:border-custom-pink"
            />
            <div className="absolute left-4 top-3">
              <IoIosSearch />
            </div>
          </div>
          <div className="text-3xl font-bold">
          <img
              src={black}
              alt="Logo"
              className="w-72 h-72 mr-36 hidden md:block"
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </div>
          <div className="flex items-center space-x-5">
            <FaBell style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' /> {/* Adjusted icon size */}
            <RxAvatar style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' /> {/* Adjusted icon size */}
            <FaShoppingBag style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' /> {/* Adjusted icon size */}
          </div>
        </div>
      </header>

      <main
        className="flex-grow flex flex-col items-center justify-center py-5 md:py-10 px-4 mb-2"
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 2, ease: "easeOut" }}
      >
      <h1 className="text-4xl md:text-6xl font-bold mb-4">We Are Coming Soon</h1>
      <p className="mb-8 text-center">Sign up to be the first to know when we go live.</p>
      <form className="flex flex-col items-center w-full max-w-md">
        <input type="email" placeholder="Enter your email here*" className="p-2 border-b border-custom-pink focus:outline-none focus:border-custom-pink w-full" />
        <button type="submit" className="bg-black text-white hover:text-black hover:bg-custom-pink transition duration-300 ease-in-out py-2 px-8 mt-4">Notify Me</button>
      </form>
    </main>

      <div className="bg-white">
        <div className="container mx-auto py-5 px-4">
          <div className="flex flex-wrap space-x-8 justify-center items-center mb-28">
          
          <div className="flex flex-col items-center p-5 border-r border-dashed  border-black">
            
            <div className="text-lg font-bold text-black">FOLLOW US</div>
            <div className="flex space-x-4 mt-4">
              <a href="#" className="text-black hover:text-custom-blue">
                <FaFacebookF style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
              </a>
              <a href="#" className="text-black ">
                <FaXTwitter style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
              </a>
              <a href="#" className="text-black hover:text-custom-pink">
                <FaInstagram style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
              </a>
            </div>
          </div>

          <div>
            <h1 className='text-xl mr-5 text-left '> Coming <br/> Soon </h1> 
          </div>
    </div>
  </div>
</div>


    <footer className="bg-black w-full text-white py-6 text-center sticky bottom-0 w-full">
        <p>© 2024 by TheraDerm Ethiopia.</p>
      </footer>
    </div>
  );
};

export default ComingSoon;
